$("#dynamic-content").on("submit","#contact-form", function(event) {

    /* stop form from submitting normally */
    event.preventDefault();

    console.log("form submitted");


    grecaptcha.ready(function() {
        grecaptcha.execute('6LdFAfogAAAAAGbhiWAACwf1RccBqu1iEU4zbIS1', {action: 'contact'}).then(function(token) {

            console.log("recaptcha token generated");
            $('#recaptchaResponse').val(token);

            //serialize form and send to server
            $("#contact").addClass("message-sent");
            $.post("../assets/form.php", $("#contact-form").serialize())
            .done(function(data) {
                if (data == 1) {
                    $("#message-sent").addClass("show");
                } else {
                    $("#message-error").addClass("show");
                }
            })
            .fail(function() {
                $("#message-error").addClass("show");
            })
        });
    });
    
  
 });
