var lastY = 0;

$(window).on("scroll", function() {
    var menu = $("#navigation");
    if (window.scrollY > 50) {
        menu.addClass("collapse");
    }

    if (window.scrollY > 50) {
        menu.addClass("opague");
    }

    if (window.scrollY < 50 && lastY > window.scrollY) {
        menu.removeClass("collapse opague");
    }

    if (menu.hasClass("hold")) {
        window.setTimeout(function() {
            $("#navigation").removeClass("hold");
        }, 500);
    }

    lastY = window.scrollY;
});

$(document).ready(function() {
    $("#tfw-logo-menu").on("mouseover", function() {

        $("#navigation").removeClass("collapse");
    });

    $('#nav-icon-small').on("click", function(){
        $("#navigation-mobile").toggleClass("collapse");
    });
    
    $('#back-to-top').on("click", function() {
        window.scrollTo({top: 0, behavior: 'smooth'});
    });

    $("#lang-menu-switch a, #lang-footer-switch a").on("click", function(event) {
        event.preventDefault();
        var path = window.location.pathname;
        var newlang = "/en";

        if ($(this).attr("class") == "lang-nl") {
            newlang = "/nl";
        }

        window.location.pathname = newlang + path.substring(3);
    })

})
