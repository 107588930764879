import { ajax } from "jquery";

$("body").on("click", "a:not([target])", function(event) {
    event.preventDefault();

    $("#navigation").removeClass("collapse").addClass("hold");
    $("#navigation-mobile").addClass("collapse");

    var href = $(this).attr("href");

    if (location.hostname === "localhost" && href.indexOf("#") < 0) {
        href += ".html";
    }

    var url = new URL(href, document.baseURI);

    //if the link points to the same page, do not load a new page
    if (window.location.pathname == url.pathname) {
        //check for hashes
        if (url.hash != "") {
            history.replaceState({'scrollY': window.scrollY}, "");
            $(url.hash)[0].scrollIntoView({behavior: "smooth"});
            history.pushState({'scrollY': window.scrollY}, "", href);
            return false;
        }
    }

    if (history.pushState) {
        history.replaceState({'scrollY': window.scrollY}, "");
        history.pushState({'scrollY': 0}, "", href);
    }

    loadPage(href);

    return false;
});

// Used to detect initial (useless) popstate.
// If history.state exists, assume browser isn't going to fire initial popstate.
var popped = ('state' in window.history && window.history.state !== null), initialURL = location.href;
// Prevent scroll jumps before fades
if ('scrollRestoration' in history) {
    history.scrollRestoration = 'manual';
  }

// Set page var
var lastpath = window.location.pathname;

// listen to popstate events
$(window).on('popstate', function (event) {
    // Ignore inital popstate that some browsers fire on page load
    var initialPop = !popped && location.href == initialURL;
    popped = true;
    //if (initialPop) return;

    //if the link points to the same page, do not load a new page
    if (window.location.pathname == lastpath) {
        //check for hashes
        if (window.location.hash != "") {
            //history.replaceState({'scrollY': window.scrollY}, "");
            $(window.location.hash)[0].scrollIntoView({behavior: "smooth"});
            //history.pushState({'scrollY': window.scrollY}, "", href);
            return false;
        }
    }

    $("#navigation").removeClass("collapse").addClass("hold");
    var state = event.originalEvent.state;

    loadPage(window.location.pathname, state.scrollY);

    return false;
});


function loadPage(page, scrolltotop = 0) {
    //page = page.replace("../","");
    //freeze content height during loading
    lastpath = window.location.pathname;

    changeBackground();
    var loaded = false;
    var time = false;

    window.setTimeout(function() {
        time = true;
        fadePageIn();
    }, 300);

    $("#dynamic-content").removeClass("fade-in");
    window.setTimeout(function() {
        window.scrollTo(0, scrolltotop);

        window.scrollTo(0, scrolltotop);
        $("#dynamic-content").load(page+" #loadcontent", function() {
            loaded = true;
            fadePageIn();
            highlightFooter();
        });



    }, 200);

    function fadePageIn() {
        if (loaded && time) {
            console.log($("#dynamic-content img"));
            var datenow = new Date();
            var starttime = datenow.getTime();
           
            $('#dynamic-content img').each(function(){
                var img = new Image();
                var t = $(this);
                t.css("opacity", 0);
                img.onload = function() {
                    var datenow = new Date();
                    if (datenow.getTime() - starttime > 250) {
                        t.animate({opacity: 1}, 400);
                    } 
                    t.css("opacity", "unset");
                    //console.log($(this).attr('src') + ' - done!');
                  
                }
                img.src = $(this).attr('src');
            });


            //initializeScrollDetection();
            $("#dynamic-content").addClass("fade-in");

            //$("#dynamic-content").animate({opacity: 1}, 500);

            if (window.location.hash != "" ) {
                $(window.location.hash)[0].scrollIntoView();
            } else {
                window.scrollTo(0, scrolltotop);
            }

        }

        var themecolor = $("#loadcontent").attr("data-theme-color");
        document.querySelector('meta[name="theme-color"]').setAttribute("content", themecolor);

    }

    function changeBackground() {
        var pageclass = page;
        if (pageclass.search(/\/\w\w\//) == 0) {
            pageclass = pageclass.substring(4);
        }

        if (pageclass.search(/\.\./) == 0) {
            pageclass = "";
        }
        
        pageclass = pageclass.replace(".html","").replace(/\//gi," ");
        
        $("body").removeClass().addClass(pageclass);
        
    }

    
}

$(document).ready(function() {
    if (window.location.hash != "") {
        $(window.location.hash)[0].scrollIntoView();
    }
    highlightFooter();
})

function highlightFooter() {
    var thispath = window.location.pathname;

    $("#footer-portfolio a").removeClass("highlight").each(function() {
        if ($(this).attr("href") == thispath) {
            $(this).addClass("highlight");
            return;
        }
    })
}
